import { createSlice } from "@reduxjs/toolkit";
var initialStateCommonReducer = {
    isNotFoundPageOpen: false,
};
var common = createSlice({
    name: "commonReducer",
    initialState: initialStateCommonReducer,
    reducers: {
        isNotFountPageOpen: function (state, action) {
            state.isNotFoundPageOpen = action.payload.isOpen;
        },
    },
});
export var commonReducer = common.reducer;
export var isNotFountPageOpen = common.actions.isNotFountPageOpen;
