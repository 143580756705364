var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { createSlice } from "@reduxjs/toolkit";
import { notificationAPI } from "../../../api/API";
import { setMessageToUserForModal } from "../../modalReducer";
var initialNotificationState = {
    notificationsResponse: {},
    fetchingNotifications: true,
    notificationData: [],
    triggeredDeleteNotification: false,
};
var slice = createSlice({
    name: "notifications",
    initialState: initialNotificationState,
    reducers: {
        setNotificationResponse: function (state, action) {
            state.notificationsResponse = action.payload;
        },
        setNotificationData: function (state, action) {
            action.payload.forEach(function (notification) { return state.notificationData.push(notification); });
        },
        setFetchingNotifications: function (state, action) {
            state.fetchingNotifications = action.payload;
        },
        getClearNotificationsData: function (state) {
            state.notificationData = [];
        },
        getTriggerDeleteNotification: function (state) {
            state.triggeredDeleteNotification = !state.triggeredDeleteNotification;
        },
    },
});
export default slice.reducer;
export var setNotificationResponse = (_a = slice.actions, _a.setNotificationResponse), setNotificationData = _a.setNotificationData, setFetchingNotifications = _a.setFetchingNotifications, getClearNotificationsData = _a.getClearNotificationsData, getTriggerDeleteNotification = _a.getTriggerDeleteNotification;
export var fetchNotifications = function (currentPage, isManyPages) { return function (dispatch) {
    return notificationAPI
        .getNotifications(currentPage)
        .then(function (res) {
        var _a;
        dispatch(setNotificationResponse(res.data));
        dispatch(setNotificationData((_a = res.data) === null || _a === void 0 ? void 0 : _a.results));
    })
        .catch(function (error) { return console.log(error); })
        .finally(function () { return dispatch(setFetchingNotifications(false)); });
}; };
export var deleteNotifcation = function (id) { return function (dispatch) {
    return notificationAPI
        .deleteNotification(id)
        .then(function (res) {
        dispatch(setMessageToUserForModal(res.data));
    })
        .catch(function (error) {
        console.log(error);
    });
}; };
export var fetchNotificationsThunkForRQ = function (currentPage) {
    if (currentPage === void 0) { currentPage = 1; }
    return __awaiter(void 0, void 0, void 0, function () {
        var response, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, notificationAPI.getNotifications(currentPage)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    err_1 = _a.sent();
                    console.log(err_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
};
