import { createSlice } from "@reduxjs/toolkit";
var initialStateSeo = {};
var seo = createSlice({
    name: "seo",
    initialState: initialStateSeo,
    reducers: {
        addMetaField: function (state, action) {
            state[action.payload.name] = action.payload.value;
        },
    },
});
export var seoReducer = seo.reducer;
export var addMetaField = seo.actions.addMetaField;
