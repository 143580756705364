var _a;
import { createSlice } from "@reduxjs/toolkit";
import { createNewsAPI, refreshEventAndNewsAPI } from "../api/API";
import { changeLoading } from "./loaderReduser";
var initialRefreshNewsAndEventsForProfile = {
    allDataById: {},
    tagForNews: {},
    categoriesForNews: {},
    typeMaterial: null,
};
var RefreshNewsAndEventsForProfileReducer = createSlice({
    name: 'RefreshNewsAndEventsForProfile',
    initialState: initialRefreshNewsAndEventsForProfile,
    reducers: {
        allDataByIdMaterial: function (state, action) {
            state.allDataById = action.payload;
        },
        getAllTags: function (state, action) {
            state.tagForNews = action.payload;
        },
        getCatigories: function (state, action) {
            state.categoriesForNews = action.payload;
        },
        setTypeMaterialForRefreshNewsOrEvents: function (state, action) {
            state.typeMaterial = action.payload;
        },
        setClearDataForUserEditor: function (state) {
            state.allDataById = {};
        },
    }
});
export default RefreshNewsAndEventsForProfileReducer.reducer;
export var allDataByIdMaterial = (_a = RefreshNewsAndEventsForProfileReducer.actions, _a.allDataByIdMaterial), getAllTags = _a.getAllTags, getCatigories = _a.getCatigories, setTypeMaterialForRefreshNewsOrEvents = _a.setTypeMaterialForRefreshNewsOrEvents, setClearDataForUserEditor = _a.setClearDataForUserEditor;
export var getAllDataForRefreshNewsAndEventsById = function (id) { return function (dispatch) {
    dispatch(changeLoading({ isLoading: true }));
    refreshEventAndNewsAPI.getAllDataByIdNewsOrEvents(id)
        .then(function (res) {
        dispatch(allDataByIdMaterial(res.data));
        dispatch(changeLoading({ isLoading: false }));
    })
        .catch(function (error) {
        console.log(error);
        dispatch(changeLoading({ isLoading: false }));
    });
}; };
export var getTagsForNews = function () { return function (dispatch) {
    createNewsAPI.getTagsForCreateNews()
        .then(function (res) {
        console.log(res.data);
        dispatch(getAllTags(res.data));
    });
}; };
export var getCatigoriesForNews = function () { return function (dispatch) {
    createNewsAPI.getCategories()
        .then(function (res) {
        console.log(res.data);
        dispatch(getCatigories(res.data));
    });
}; };
export var refreshEventOrNewsById = function (id, data) { return function (dispatch) {
    dispatch(changeLoading({ isLoading: true }));
    refreshEventAndNewsAPI.refreshDataForNewsOrEvents(id, data)
        .then(function (res) {
        console.log('после редактирования рефреш', res);
        dispatch(changeLoading({ isLoading: true }));
    })
        .catch(function (error) {
        console.log('ошибка рефреш', error);
        dispatch(changeLoading({ isLoading: false }));
    });
}; };
