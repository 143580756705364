import { createSlice } from "@reduxjs/toolkit";
export var initialRightSetState = {};
var rightSet = createSlice({
    name: "rightSet",
    initialState: initialRightSetState,
    reducers: {
        setRightSet: function (state, action) {
            state.rightSet = action.payload;
        },
    },
    extraReducers: {
    // [fetchRightSet.fulfilled.type]: (state, action: PayloadAction<any>) => {
    //   state.isLoading = false;
    //   state.RightSet = action.payload;
    // },
    // [fetchRightSet.pending.type]: (state) => {
    //   state.isLoading = true;
    // },
    // [fetchRightSet.rejected.type]: (state, action: PayloadAction<string>) => {
    //   state.isLoading = false;
    //   state.error = action.payload;
    // },
    },
});
export var setRightSet = rightSet.actions.setRightSet;
export default rightSet.reducer;
