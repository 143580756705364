var _a;
import { createSlice } from "@reduxjs/toolkit";
import { fetchRightsetDataInUserProfile } from "./fetchRightsetDataInUserProfileAction";
var initialStateRightSetUserProfile = {};
var slice = createSlice({
    name: 'getRightsetDataInUserProfile',
    initialState: initialStateRightSetUserProfile,
    reducers: {},
    extraReducers: (_a = {},
        _a[fetchRightsetDataInUserProfile.pending.type] = function (state) {
            state.isLoading = true;
        },
        _a[fetchRightsetDataInUserProfile.fulfilled.type] = function (state, action) {
            state.isLoading = false;
            state.dataRightsetUserProfile = action.payload;
        },
        _a[fetchRightsetDataInUserProfile.rejected.type] = function (state, action) {
            state.isLoading = false;
            state.Error = action.payload;
        },
        _a)
});
export default slice.reducer;
