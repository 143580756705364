import { useDispatch } from "react-redux";
import thunkMiddleware from "redux-thunk";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./authReducer";
import { newsReducer } from "./newsReducer";
import { menuReducer } from "./menuReducer";
import { eventsReducer } from "./reducers/eventsPage/eventsReducer";
import modalReducer from "./modalReducer";
import rightSetReducer from "./rightSetReducer";
import { openNewsReducer } from "./reducers/openedNewsReducer/openNewsReducer";
import { openEventsReducer } from "./reducers/openedEventsReducer/openEventsReducer";
import { openArticlesReducer } from "./reducers/openedArticlesReducer/openArticlesReducer";
import loaderReduser from "./loaderReduser";
import articleReducer from "./articleReducer";
import searchCompanyReducer from "./searchCompanyReducer";
import companiesPeducer from "./companiesPeducer";
import { likesReducer } from "./likesReduser";
import registrationReducer from "./registrationReducer";
import getRightsetDataInUserProfileReducer from "./reducers/fetchRightsetDataInUserProfile/getRightsetDataInUserProfile";
import userProfileReducer from "./reducers/userProfile/userProfileReduce";
import filteredEventsReducer from "./reducers/getFilteredEvents/filteredEventsReducer";
import { updateUserReducer } from "./updateUserReducer";
import getUserByIdReducer from "./reducers/getUserById/getUserById";
import companyProfileReducer from "./reducers/companyProfile/companyProfile";
import adminProfileReducer from "./reducers/adminProfile/adminProfile";
import notificationReducer from "./reducers/notifications/notifications";
import startupProfileReducer from "./reducers/startupProfile/startupProfile";
import ExpertProfile from "./reducers/ExpertProfile/ExpertProfile";
import popupReducer from "./popupReducer";
import bookmarkedReducer from "./reducers/companyByIdReducer/bookmark/bookmark";
import { aboutUsReducer } from "./aboutUsReducer";
import vacanciesReduser from "./vacanciesReduser";
import companyByIdReducer from "./reducers/companyByIdReducer/companyByIdReducer.";
import userByIdReducer from "./reducers/userByIdReducer/userByIdReducer.";
import expertByIdReducer from "./reducers/expertByIdReducer/expertByIdReducer";
import AdminModeratorReducer from "./reducers/AdminModeratorReducer/AdminModeratorReducer";
import RefreshNewsAndEventsForProfileReducer from "./refreshNewsAndEventsForProfileReducer";
import searchFollowsAndFollowersReducer from "./reducers/SearchFollowsAndFollowers/SearchFollowsAndFollowers";
import AdminAdsModerationReducer from "./reducers/AdminModeratorReducer/AdminAdsModeration";
import globalSearchReducer from "./globalSearchReducer";
import feedbackReduser from "./feedbackReducer";
import { seoReducer } from "./reducers/seo/seo";
import headcatsReducer from "./reducers/headcatsReducer/headcatsReducer";
import { commonReducer } from "./reducers/commonReducer/commonReducer";
import startupByIdReducer from "./reducers/startupByIdReducer/startupByIdReducer";
import startupsPageReducer from "./startupsReducer";
var rootReducer = combineReducers({
    AdminModerator: AdminModeratorReducer,
    adminAdsModeration: AdminAdsModerationReducer,
    auth: authReducer,
    article: articleReducer,
    adminProfile: adminProfileReducer,
    commonReducer: commonReducer,
    headcatsReducer: headcatsReducer,
    bookmarked: bookmarkedReducer,
    getRightsetDataInUserProfile: getRightsetDataInUserProfileReducer,
    getUserById: getUserByIdReducer,
    filteredEvents: filteredEventsReducer,
    userProfile: userProfileReducer,
    companyProfile: companyProfileReducer,
    expertProfile: ExpertProfile,
    news: newsReducer,
    menu: menuReducer,
    events: eventsReducer,
    rightSet: rightSetReducer,
    modal: modalReducer,
    popup: popupReducer,
    openNews: openNewsReducer,
    openEvents: openEventsReducer,
    openArticles: openArticlesReducer,
    globalSearchReducer: globalSearchReducer,
    isLoading: loaderReduser,
    notifications: notificationReducer,
    searchUsersReducer: searchCompanyReducer,
    companyPage: companiesPeducer,
    likes: likesReducer,
    registration: registrationReducer,
    companyById: companyByIdReducer,
    userById: userByIdReducer,
    expertById: expertByIdReducer,
    updateUser: updateUserReducer,
    aboutUs: aboutUsReducer,
    vacancies: vacanciesReduser,
    seo: seoReducer,
    searchFollowsAndFollowers: searchFollowsAndFollowersReducer,
    refreshNewsAndEventsForProfile: RefreshNewsAndEventsForProfileReducer,
    feedback: feedbackReduser,
    startup: startupProfileReducer,
    startupById: startupByIdReducer,
    startups: startupsPageReducer
});
export var store = configureStore({
    reducer: rootReducer,
    middleware: function (getDefaultMiddleware) { return getDefaultMiddleware().prepend(thunkMiddleware); },
});
export var useAppDispatch = function () { return useDispatch(); };
