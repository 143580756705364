var _a;
import { createSlice } from "@reduxjs/toolkit";
import { openedPublicationAPI } from "../../../api/API";
import { changeLoading } from "../../loaderReduser";
var initialStateOpenNews = {
    openedPublication: {},
};
var slice = createSlice({
    name: "openEvents",
    initialState: initialStateOpenNews,
    reducers: {
        setOpenPublication: function (state, action) {
            state.openedPublication = action.payload;
        },
        getClearOpenEvents: function (state) {
            state.openedPublication = {};
        },
    },
});
export var openEventsReducer = slice.reducer;
export var setOpenPublication = (_a = slice.actions, _a.setOpenPublication), getClearOpenEvents = _a.getClearOpenEvents;
export var getOpenedPublicationTH = function (type, id) { return function (dispatch) {
    dispatch(changeLoading({ isLoading: true }));
    openedPublicationAPI
        .getOpenPublication(type, id)
        .then(function (res) {
        dispatch(setOpenPublication(res.data));
        dispatch(changeLoading({ isLoading: false }));
    })
        .catch(function (error) {
        console.log("Текущую публикацию не получили" + error);
    });
}; };
